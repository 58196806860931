<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('BOT.TITLE') }}</h5>
      <!--end::Title-->
      <!--begin::Separator-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200 d-none d-sm-block" />
      <!--end::Separator-->
      <!--begin::Search Form-->
      <div id="kt_subheader_search" class="d-flex align-items-center">
        <span v-if="pagination.totalCount" id="kt_subheader_total"
              class="text-dark-50 font-weight-bold mr-5"
        >{{ pagination.totalCount }} {{ $t('BASE.TOTAL') }}</span>
        <form @submit.stop.prevent="">
          <b-input-group class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <InputForm
              id="kt_subheader_search_form"
              v-model="query.search"
              :placeholder="$t('BASE.SEARCH')"
              debounce="300"
              trim
              type="text"
            />
            <b-input-group-append>
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
      <!--end::Search Form-->
    </div>
  </template>
  <template #toolbar>
    <div v-permission="['edit_bot']" class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyBotCreate' }" class="btn btn-light-success font-weight-bold btn-sm">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('BOT.NEW') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <Table v-cloak :busy.sync="$loading" :fields="fields" :items="pagination.items">
      <template #cell(name)="data">
        <div style="min-width: 150px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.name }} </span>
        </div>
      </template>
      <template #cell(actions)="data">
        <div style="min-width: 80px">
          <b-btn v-permission="['edit_bot']"
                 class="btn btn-icon btn-sm mr-3"
                 @click="toggleBotActivity(data.item)"
                 :class="[activeBotIds.includes(data.item.uuid)? 'btn-light-success':'btn-light']"
          >
            <span class="svg-icon btn-light-primary" :title="$t('FORM.ACTIVE')">
              <inline-svg src="/media/svg/icons/Code/Done-circle.svg" v-if="activeBotIds.includes(data.item.uuid)" />
              <inline-svg src="/media/svg/icons/Code/Error-circle.svg" v-else />
            </span>
          </b-btn>
          <router-link v-permission="['edit_bot']"
                       :to="{ name: 'companyBotEdit', params: { id: data.item.uuid }}"
                       class="btn btn-icon btn-light-info btn-sm mr-3"
          >
            <span class="svg-icon btn-light-primary">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </router-link>
          <div v-permission="['edit_bot']" class="btn btn-icon btn-light-danger btn-sm"
               @click="remove(data.item)"
          >
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Home/Trash.svg" />
            </span>
          </div>
        </div>
      </template>
    </Table>
    <div class="mt-3">
      <pager v-model="query.pagination.page" :pagination="pagination" />
    </div>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/botRepository'
import InputForm from '@/components/forms-items/input'
import Table from '@/components/UI/Table'
import Pager from '@/components/UI/Pager'
import KTCard from '@/view/content/Card'
import Pagination from '@/model/pagination'
import Vue from 'vue'

export default {
  name: 'CompanyBotIndex',
  components: {
    KTCard,
    InputForm,
    Pager,
    Table,
  },
  data() {
    return {
      activeBotIds: [],
      pagination: new Pagination(),
      query: {
        search: null,
        pagination: {
          page: 1,
        },
      },
      fields: [
        {
          key: 'name',
          label: this.$t('BOT.NAME'),
          sortable: false,
        },
        {
          key: 'token',
          label: this.$t('FORM.TOKEN'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.BOT') }])
  },
  methods: {
    load() {
      this.loadingOn()
      Repo.paginate(this.query)
        .then((pagination) => this.pagination = pagination)
        .then(this.loadState)
        .catch(console.log)
        .finally(this.loadingOff)
    },
    toggleBotActivity(bot) {
      this.loadingOn()
      if (this.activeBotIds.includes(bot.uuid)) {
        Repo.deactivate(bot.uuid)
          .then(this.loadState)
          .catch(this.$toastDanger('TOAST.ERROR'))
          .finally(this.loadingOff)
        return
      }
      Repo.activate(bot.uuid)
        .then(this.loadState)
        .catch(this.$toastDanger('TOAST.ERROR'))
        .finally(this.loadingOff)
    },
    loadState() {
      this.pagination.items.forEach((bot) => {
        Vue.prototype.$http.get(`https://api.telegram.org/bot${bot.token}/getWebhookInfo`)
          .then((r) => {
            if (r.data.ok) {
              if (r.data.result.url !== '') {
                this.activeBotIds.push(bot.uuid)
              } else if (this.activeBotIds.includes(bot.uuid)) {
                this.$delete(this.activeBotIds, this.activeBotIds.indexOf(bot.uuid))
              }
            }
          }).catch(console.log)
      })
    },
    remove(ent) {
      this.$confirmAction()
        .then(this.loadingOn)
        .then(() => {
          Repo.remove(ent)
            .then(this.$toastSuccess('TOAST.CREATED'))
            .then(this.load)
            .catch(this.loadingOff)
        })
    },
  },
}
</script>

<style scoped>

</style>
